export const GA_TRACKING_ID = 'AW-811813558/RP2BCNnuqZoBELaVjYMD'; // Replace with your Tracking ID

export const pageview = (url) => {
    if (typeof window !== 'undefined') {
      window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
      });
    }
  };
  
  export const event = ({ action, category, label, value }) => {
    if (typeof window !== 'undefined') {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }
  };
  export const trackProductUnavailability = (productName, pinCode) => {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'product_unavailability', {
        event_category: 'Product Interaction',
        event_label: `Product Unavailable: ${productName} - Pin Code: ${pinCode}`,
      });
    }
  };
  export const gtagConversionEvent = () => {
      window.gtag('event', "conversion", {
        // event_category: category,
        // event_label: label,
        value: 1,
      });
  };
  export const getBrowserName = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('chrome') > -1) return 'Chrome';
    if (userAgent.indexOf('firefox') > -1) return 'Firefox';
    if (userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1) return 'Safari';
    if (userAgent.indexOf('msie') > -1 || userAgent.indexOf('trident') > -1) return 'Internet Explorer';
    if (userAgent.indexOf('edge') > -1) return 'Edge';
    return 'Unknown';
};
  