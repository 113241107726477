import { ApolloClient,createHttpLink, InMemoryCache } from "@apollo/client";
// // enable cors
// var corsOptions = {
//     origin: true,
//     credentials: true // <-- REQUIRED backend setting
//   };
//   app.use(cors(corsOptions));

  const headers = {
    "Access-Control-Allow-Origin": "*" // Required for CORS support to work
}

const httpLink = createHttpLink({
    uri: 'http://orientbell.com/graphql',
  });

const client = new ApolloClient({
    //link: headers.concat(httpLink),
    uri: 'https://proxy.orientbell.com/',
    cache: new InMemoryCache(),
    fetchOptions: {
        mode: 'no-cors',
      },
});

export default client;

